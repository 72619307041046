<template>
	<announcement-data
		:form-import="form"
		:create-new-announcement="true"
		:errors-import="errors"
		:priorities="priorities"
		:themes="themes"
		@requestData="requestCreateAnnouncement" />
</template>
<script>
import { PRIORITIES, THEMES, DEFAULTS } from '@/settings/Announcements';
import { Announcements as messages } from '@/translations';
import Announcement from '@/util/Announcement';
import AnnouncementData from './components/AnnouncementData';

export default {
	name: 'NewAnnouncement',
	messages,
	components: {
		AnnouncementData,
	},
	data() {
		return {
			announcement: new Announcement(),
			loading: false,
			priorities: PRIORITIES,
			themes: THEMES,
			alert: new this.$Alert(),
			form: {
				// title: '',
				content: '',
				// regards: '',
				// signature: '',
				theme: DEFAULTS.theme,
				priority: DEFAULTS.priority,
				allow_to_hide: true,
				start_date: '',
				end_date: '',
				password: '',
			},
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.announcement.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.announcement.data.errors.errors = newErrors;
			},
		},
	},
	created() {
		// this.form.regards = this.translate(this.form.regards);
		// this.form.signature = this.translate(this.form.signature, { app: process.env.VUE_APP_NAME });
	},
	methods: {
		requestCreateAnnouncement(payload) {
			this.announcement.createAnnouncement(payload).then(() => {
				try {
					this.alert.toast('success', this.translate('announcement_created'));
				} finally {
					this.$router.push({ name: 'Announcements' });
				}
			});
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
	},
};
</script>
